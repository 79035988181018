import coreAxios, { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';

const axios = coreAxios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 10 * 60 * 1000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'max-age=0',
  },
});

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response) {
      Sentry.captureException(error.response.data);
    } else {
      Sentry.captureException(error.message);
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

//
// Axios
//
const axiosHttp = coreAxios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
  timeout: 10 * 60 * 1000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

axiosHttp.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response) {
      Sentry.captureException(error.response.data);
    } else {
      Sentry.captureException(error.message);
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

//
// Axios file
//
const axiosFile = coreAxios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
  timeout: 10 * 60 * 1000,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json',
  },
});

export { axios, axiosHttp, axiosFile };
