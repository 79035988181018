import React from 'react';
import { RouteObject } from 'react-router-dom';

export const categoriesRoutes: Array<RouteObject & RouterRoute> = [
  {
    path: ':oid',
    Component: React.lazy(() => import('./views/Category')),
    index: true,
    layout: 'common',
  },
  {
    path: ':oid/:type',
    Component: React.lazy(() => import('./views/Category')),
    index: true,
    layout: 'common',
  },
];
