import 'css/ui/generalUi.css';

export function Button({
  children,
  className = 'w-96',
  color = 'secondary',
  onClick = () => {},
  disabled = false,
}: {
  children: React.ReactNode;
  className?: string;
  color?: string;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
}) {
  return (
    <button
      className={`p-1 rounded-full custom-btn-${color} ${className}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
