import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDoubleUpIcon } from '@heroicons/react/24/outline';
import { HomeIcon, XMarkIcon } from '@heroicons/react/24/solid';

import { useAuth, useCore } from 'context';

import { Button, Modal } from 'components/ui';
import { Basket, ModalCGV } from 'components/checkout';

export const FooterCommonLayout = () => {
  const { basket, reset } = useCore();
  const { client } = useAuth();
  const navigate = useNavigate();

  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [showBasket, setShowBasket] = useState<boolean>(false);
  const [openModalCGV, setOpenModalCGV] = useState<boolean>(false);

  const handleClose = async () => {
    setOpenModalConfirm(false);
    await reset();
    navigate('/');
  };

  return (
    <>
      <Basket setOpen={e => setShowBasket(!!e)} opened={showBasket} />
      <div className="fixed bottom-0 left-0 h-52 w-full bg-white flex justify-between items-center p-12 z-50">
        <div className="absolute bottom-full h-12 bg-gradient-to-t from-black opacity-20 inset-x-0 z-20" />
        <div
          className="absolute bottom-full left-1/2 -translate-x-1/2 translate-y-1/2 -mb-2 z-30"
          onClick={() => setShowBasket(!showBasket)}
        >
          <div className="bg-white -inset-x-10 h-28 absolute top-1/2 z-20 -mt-2" />
          <div className="h-20 w-20 rounded-full shadow-[0_0_60px_rgba(0,0,0,0.3)] bg-white z-10" />
          <ChevronDoubleUpIcon
            className={`h-12 w-12 absolute z-40 left-1/2 top-2 -translate-x-1/2 transition-all ease-linear duration-300 ${
              showBasket ? 'rotate-180' : 'rotate-0'
            }`}
          />
          <div className="absolute z-40 -translate-x-1/2 left-1/2 top-16 font-bold text-sm text-gray-400 w-52 text-center">
            Détail du panier
          </div>
        </div>

        <div className="relative z-30">
          <p className="text-primary text-2xl text-special">
            {basket?.lignes?.reduce((acc, item) => acc + item.quantite, 0) || 0}{' '}
            produit(s) dans le panier
          </p>
          <p className="text-6xl text-light">
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(basket?.total || 0)}
          </p>
        </div>
        <div className="flex flex-col gap-4 justify-between w-72">
          <Button
            className="w-full text-2xl py-2 text-special uppercase"
            onClick={() => {
              if (basket?.total === 0) return;
              setOpenModalCGV(true);
            }}
          >
            Valider et payer
          </Button>
          <Button
            className="w-full text-red-800 font-black border-4 border-red-800 text-2xl py-2 uppercase flex items-center justify-center gap-2"
            color="none"
            onClick={() => setOpenModalConfirm(true)}
          >
            {client?.client?.clientPassage ? (
              <>
                <HomeIcon className="inline w-6 h-6" /> Quitter
              </>
            ) : (
              <>
                <XMarkIcon className="inline w-8 h-8" /> Déconnexion
              </>
            )}
          </Button>
        </div>
      </div>
      <Modal
        open={openModalConfirm}
        title="Veuillez confirmer"
        height="h-[26rem]"
      >
        <p className="uppercase">
          En cliquant sur confirmer, votre panier sera perdu et vous serez
          déconnecté(e). Confirmez votre choix.
        </p>
        <Modal.Footer>
          <div className="flex gap-4">
            <Button
              onClick={() => setOpenModalConfirm(false)}
              color="white-with-border"
              className="text-2xl py-2 px-6 w-44"
            >
              Annuler
            </Button>

            <Button
              className="text-2xl py-2 px-6 w-44"
              onClick={() => handleClose()}
            >
              Confirmer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalCGV open={openModalCGV} setOpen={e => setOpenModalCGV(!!e)} />
    </>
  );
};
