import { useState, useEffect } from 'react';
import {
  format,
  lastDayOfMonth,
  startOfMonth,
  eachDayOfInterval,
  addMonths,
  subMonths,
  getDay,
  addWeeks,
} from 'date-fns';
import { fr } from 'date-fns/esm/locale';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

export const Calendar = ({
  onSelect,
}: {
  onSelect: (date: string) => void;
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [dates, setDates] = useState<Array<Date | number>>([]);
  const lastDate = addWeeks(new Date(), 3);

  useEffect(() => {
    getDatesInRange(currentDate);
  }, [currentDate]);

  function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(' ');
  }

  function getDatesInRange(month: Date) {
    setDates([]);
    const today = new Date(month);
    let firstDateOfMonth;
    const lastDateOfMonth = lastDayOfMonth(month);

    if (
      format(new Date(month), 'dd/MM/yyyy') === format(new Date(), 'dd/MM/yyyy')
    ) {
      firstDateOfMonth = new Date(today);
    } else {
      firstDateOfMonth = startOfMonth(month);
    }

    const _tmpDates = eachDayOfInterval({
      start: firstDateOfMonth,
      end: lastDateOfMonth,
    });

    const _tmpFirstDays = Array.from(Array(getDay(_tmpDates[0])).keys());
    _tmpFirstDays.pop();

    setDates([..._tmpFirstDays, ..._tmpDates]);
  }

  function handleChangeMonth(direction: string) {
    if (direction === 'previous') {
      setCurrentDate(prev => subMonths(new Date(prev), 1));
    } else {
      setCurrentDate(prev => addMonths(new Date(prev), 1));
    }
  }

  console.log(lastDate);

  return (
    <div>
      <div className="text-center text-3xl xl:col-start-9">
        <div className="flex items-center text-gray-900">
          <button
            type="button"
            className={clsx(
              '-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500',
              format(new Date(currentDate), 'LLLL') ===
                format(new Date(), 'LLLL') && 'opacity-30',
            )}
            disabled={
              format(new Date(), 'LLLL') ===
              format(new Date(currentDate), 'LLLL')
            }
            onClick={() => handleChangeMonth('previous')}
          >
            <span className="flex items-center">
              <ChevronLeftIcon className="h-6 w-6 mr-2" /> Précédent
            </span>
          </button>
          <div className="flex-auto font-semibold capitalize ">
            {format(new Date(currentDate), 'LLLL', { locale: fr })}
          </div>
          <button
            type="button"
            onClick={() => handleChangeMonth('next')}
            disabled={lastDayOfMonth(new Date(currentDate)) > lastDate}
            className={clsx(
              '-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500',
              lastDayOfMonth(new Date(currentDate)) > lastDate && 'opacity-30',
            )}
          >
            <span className="flex items-center">
              Suivant <ChevronRightIcon className="w-6 h-6 ml-2" />
            </span>
          </button>
        </div>
        <div className="mt-[2.5rem] grid grid-cols-7 leading-6 text-gray-500">
          <div>L</div>
          <div>M</div>
          <div>M</div>
          <div>J</div>
          <div>V</div>
          <div>S</div>
          <div>D</div>
        </div>
        <div className="isolate mt-4 grid grid-cols-7 gap-px rounded-lg bg-gray-300 shadow ring-1 ring-gray-200">
          {dates.map((day, dayIdx) =>
            typeof day !== 'number' ? (
              <button
                key={dayIdx}
                type="button"
                className={classNames(
                  'py-[1.5rem] focus:z-10 ',
                  dayIdx === 0 && 'rounded-tl-lg',
                  dayIdx === 6 && 'rounded-tr-lg',
                  dayIdx === dates.length - 7 && 'rounded-bl-lg',
                  dayIdx === dates.length - 1 && 'rounded-br-lg',
                  new Date(day) > lastDate
                    ? 'bg-transparent text-gray-400 opacity-30'
                    : 'bg-gray-50 hover:bg-gray-200',
                )}
                onClick={() => onSelect(format(new Date(day), 'dd/MM/yyyy'))}
                disabled={new Date(day) > lastDate}
              >
                <time
                  dateTime={format(new Date(day), 'dd', { locale: fr })}
                  className="mx-auto flex h-7 w-7 items-center justify-center"
                >
                  {format(new Date(day), 'dd', { locale: fr })}
                </time>
              </button>
            ) : (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button disabled type="button" />
            ),
          )}
        </div>
      </div>
    </div>
  );
};
