import React from 'react';
import { RouteObject } from 'react-router-dom';

export const homeRoutes: Array<RouteObject & RouterRoute> = [
  {
    path: 'auth',
    Component: React.lazy(() => import('./views/HomeWithAuth')),
    index: true,
    layout: 'common',
  },
  {
    path: 'not-auth',
    Component: React.lazy(() => import('./views/HomeWithoutAuth')),
    index: true,
    layout: 'common',
  },
];
