import { axios } from 'utils/axios-iagona';
import { useRef, useState } from 'react';
import { asyncDebounce } from 'utils/tools';
import { AxiosResponse } from 'axios';
import {
  IagonaPrintInput,
  IagonaPrintItemInput,
  IagonaStartPayment,
  IagonaStartPaymentInput,
} from 'types/iagona';
import * as Sentry from '@sentry/react';
import { useCore } from 'context/core/core.context';
import { IagonaContext } from './iagona.context';

export const IagonaProvider = ({ children }: { children: React.ReactNode }) => {
  const [started, setStarted] = useState<string[]>([]);
  const currentController = useRef<AbortController | null>(null);
  const { sendAlert } = useCore();

  const changeController = (newController: AbortController) => {
    currentController.current?.abort();
    currentController.current = newController;
  };

  const removeController = (controller: AbortController) => {
    if (currentController.current === controller) {
      currentController.current = null;
    }
  };

  const removeAllController = () => {
    if (currentController.current) {
      currentController.current.abort();
      currentController.current = null;
    }
  };

  const startInputDebounced: () => Promise<string | undefined> = useRef(
    asyncDebounce(async () => {
      const result = await handleStartInput();
      return result;
    }, 500),
  ).current;

  const startTransaction = async (): Promise<boolean> => {
    const controller = new AbortController();
    changeController(controller);

    try {
      const result = await axios.post(
        'Transaction/StartTransaction',
        {},
        { signal: controller.signal },
      );

      Sentry.captureMessage(
        `Iagona : StartTransaction ${new Date().getTime()}`,
        {
          level: 'info',
          extra: {
            status: result.status,
            data: result.data,
          },
        },
      );

      if (result.data?.Result === 'OK') {
        return true;
      }
      if (
        result.data?.Result === 'KO' &&
        ['00060007', '00070007'].includes(result.data?.APIException?.Code)
      ) {
        const result = await stopTransaction();
        if (!result) return false;
        return await startTransaction();
      }
      return true;
    } finally {
      removeController(controller);
    }
  };

  const stopTransaction = async (): Promise<boolean> => {
    const controller = new AbortController();
    changeController(controller);

    try {
      const result = await axios.post('Transaction/StopTransaction', {});

      Sentry.captureMessage(
        `Iagona : StopTransaction ${new Date().getTime()}`,
        {
          level: 'info',
          extra: {
            status: result.status,
            data: result.data,
          },
        },
      );

      if (['00060007', '00060004'].includes(result.data?.APIException?.Code)) {
        await handleStopInput(true);
        return await stopTransaction();
      }
      return result.data?.Result === 'OK';
    } finally {
      removeController(controller);
    }
  };

  const startInput = async (): Promise<string | undefined> => {
    const input = await startInputDebounced();
    return input;
  };

  const handleStartInput = async (
    InputType: 'PaymentTerminal_NFC' | 'HID_Device' = 'HID_Device',
  ): Promise<string | undefined> => {
    const controller = new AbortController();
    changeController(controller);
    setStarted([...started, 'input']);

    try {
      const result = await axios.post(
        'Input/StartInput',
        {
          InputType,
        },
        {
          timeout: 30 * 1000,
          signal: controller.signal,
        },
      );

      Sentry.captureMessage(`Iagona : StartInput ${new Date().getTime()}`, {
        level: 'info',
        extra: {
          InputType,
          status: result.status,
          data: result.data,
        },
      });

      if (result.data?.APIException?.Code === '00060004') {
        await handleStopInput(true);
        return await startInput();
      }

      if (
        result.status === 200 &&
        result.data.Result === 'OK' &&
        result.data.ReadValue
      ) {
        setStarted(started.filter(e => e !== 'input'));
        let realValue = result.data.ReadValue;
        if (InputType === 'PaymentTerminal_NFC') {
          realValue = realValue.substr(3);
        }
        if (InputType === 'HID_Device') {
          realValue = parseInt(realValue, 10).toString(16).toUpperCase();
        }
        return realValue;
      }
      return undefined;
    } catch (e) {
      await handleStopInput();
      return undefined;
    } finally {
      removeController(controller);
    }
  };

  const handleStopInput = async (force = false): Promise<void> => {
    const controller = new AbortController();
    changeController(controller);

    if (!started.includes('input') && !force) return;

    try {
      const result = await axios.post('Input/StopInput', {
        InputType: 'HID_Device',
      });

      Sentry.captureMessage(`Iagona : StopInput ${new Date().getTime()}`, {
        level: 'info',
        extra: {
          status: result.status,
          data: result.data,
        },
      });
    } finally {
      removeController(controller);
    }
  };

  const handleMaintenanceMode = async (): Promise<boolean> => {
    const controller = new AbortController();
    changeController(controller);

    try {
      const result = await axios.post('Management/ModeMaintenance', {
        ShowPasswordView: true,
      });
      return result.data?.Result === 'OK';
    } finally {
      removeController(controller);
    }
  };

  const handleGetStatus = async (): Promise<boolean> => {
    const controller = new AbortController();
    changeController(controller);

    try {
      const result = await axios.get('Management/GetStatus');
      return result.data?.State !== 'HS';
    } finally {
      removeController(controller);
    }
  };

  const handleReboot = async (): Promise<boolean> => {
    const controller = new AbortController();
    changeController(controller);

    try {
      await axios.post('Management/RebootKiosk');
      return true;
    } finally {
      removeController(controller);
    }
  };

  const handleStartPayment = async ({
    ref,
    amount,
  }: {
    ref: string;
    amount: number;
  }): Promise<boolean> => {
    const controller = new AbortController();
    changeController(controller);

    try {
      const request: IagonaStartPaymentInput = {
        InputType: 'HID_Device',
        PaymentType: 'BankCard',
        Amount: amount * 100,
        Id: ref,
        PrintTicket: true,
      };

      const result: AxiosResponse<IagonaStartPayment> = await axios.post(
        'Payment/StartPayment',
        request,
      );
      return result.data?.Result === 'OK';
    } finally {
      removeController(controller);
    }
  };

  const handleStopPayment = async ({
    ref,
  }: {
    ref: string;
  }): Promise<boolean> => {
    const controller = new AbortController();
    changeController(controller);

    try {
      const result = await axios.post('Payment/StopPayment', {
        Id: ref,
      });
      return result.data?.Result === 'OK';
    } finally {
      removeController(controller);
    }
  };

  const handlePrintTicket = async (
    items: IagonaPrintItemInput[],
  ): Promise<boolean> => {
    const controller = new AbortController();
    changeController(controller);

    try {
      const request: IagonaPrintInput = {
        PrinterType: 'Ticket',
        PrintItems: items,
        PrintMargins: {
          Bottom: 0,
          Left: 10,
          Right: 0,
          Top: 0,
        },
        PrintOrientation: 'Portrait',
      };

      const result = await axios.post('Print/PrintModel', request);

      if (result.status !== 200)
        sendAlert("Erreur lors de l'impression du ticket.");

      return result.data?.Result === 'OK';
    } finally {
      removeController(controller);
    }
  };

  return (
    <IagonaContext.Provider
      value={{
        startInput,
        stopInput: handleStopInput,
        startPayment: handleStartPayment,
        stopPayment: handleStopPayment,
        printTicket: handlePrintTicket,
        cancelCalls: removeAllController,
        maintenanceMode: handleMaintenanceMode,
        getStatus: handleGetStatus,
        reboot: handleReboot,
        startTransaction,
        stopTransaction,
      }}
    >
      {children}
    </IagonaContext.Provider>
  );
};
