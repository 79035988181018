import { useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import layout from 'simple-keyboard-layouts/build/layouts/french';
import 'react-simple-keyboard/build/css/index.css';

interface IProps {
  onChange: (input: string) => void;
}

export const DefaultKeyboard = ({ onChange }: IProps) => {
  const [layoutName, setLayoutName] = useState('default');

  const onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    }
  };

  return (
    <Keyboard
      onChange={val => onChange(val)}
      layoutName={layoutName}
      onKeyPress={onKeyPress}
      display={{
        '{bksp}': '⌫',
        '{enter}': 'Entrée',
        '{shift}': '⇧',
        '{lock}': '⇪',
        '{space}': 'Espace',
        '{tab}': 'Tab',
      }}
      {...layout}
    />
  );
};
