import coreAxios, {
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';
import * as Sentry from '@sentry/react';

interface RequestMetadata {
  startTime: Date;
  endTime?: Date;
}

interface AxiosRequestConfigWithMetadata extends AxiosRequestConfig {
  metadata?: RequestMetadata;
}

const axios = coreAxios.create({
  baseURL: process.env.REACT_APP_IAGONA_URL,
  timeout: 10 * 60 * 1000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'max-age=0',
    Authorization: 'Basic dXNlcmFwaTptZHBhcGk=',
  },
});

axios.interceptors.request.use(request => {
  const _req = request as AxiosRequestConfigWithMetadata;
  // Sentry.captureMessage('Started : Axios request', {
  //   level: 'info',
  //   extra: {
  //     method: _req.method,
  //     url: _req.url,
  //     data: _req.data,
  //   },
  // });
  _req.metadata = { startTime: new Date() };
  return request;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // const config = response.config as AxiosRequestConfigWithMetadata;
    // if (config.metadata) {
    //   config.metadata.endTime = new Date();
    // const duration =
    //   config.metadata.endTime.getTime() - config.metadata.startTime.getTime();

    // Sentry.captureMessage('Iagona : Axios response', {
    //   level: 'info',
    //   extra: {
    //     method: config.method,
    //     url: config.url,
    //     data: config.data,
    //     response: response.data,
    //     duration,
    //     status: response.status,
    //     startTime: config.metadata.startTime,
    //     endTime: config.metadata.endTime,
    //   },
    // });
    // }

    return response;
  },
  (error: AxiosError) => {
    // const config = error.config as AxiosRequestConfigWithMetadata;
    // if (config.metadata) {
    //   config.metadata.endTime = new Date();
    //   const duration =
    //     config.metadata.endTime.getTime() - config.metadata.startTime.getTime();

    //   Sentry.captureException(error, {
    //     level: 'error',
    //     extra: {
    //       method: config.method,
    //       url: config.url,
    //       data: config.data,
    //       duration,
    //       status: error.response ? error.response.status : 'Network error',
    //       startTime: config.metadata.startTime,
    //       endTime: config.metadata.endTime,
    //     },
    //   });
    // }
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

//
// Axios
//
const axiosHttp = coreAxios.create({
  baseURL: process.env.REACT_APP_IAGONA_URL,
  withCredentials: true,
  timeout: 10 * 60 * 1000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export { axios, axiosHttp };
