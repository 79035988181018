import { useEffect, useMemo, useState } from 'react';
import {
  getWebStore,
  getWebStoreMediaImageUrl,
  logas,
  whoami,
} from 'features/core/stub/token';
import { CoreConfig, Etablissement } from 'types/core';
import sign from 'jwt-encode';
import { debounce } from 'lodash';
import { Loader } from 'components';
import { ConfigContext, ConfigContextProps } from './config.context';

const CryptoJS = require('ts.cryptojs256');

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useState<CoreConfig>();
  const [currentEts, setCurrentEts] = useState<Etablissement>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    init();
    const intervalInit = setInterval(() => {
      init();
    }, 30 * 60 * 1000);
    const internalHS = setInterval(() => {
      checkHS();
    }, 2 * 60 * 1000);
    return () => {
      clearInterval(internalHS);
      clearInterval(intervalInit);
    };
  }, []);

  useEffect(() => {
    if (currentEts?.oid) setLoading(false);
  }, [currentEts]);

  const checkHS = async () => {
    if (currentEts?.oid) {
      const {
        data: { element },
      } = await getWebStore(currentEts?.oid);
      setConfig({
        ...config,
        params: {
          ...config?.params,
          horsService: element?.bornesData?.parametreHorsService === 'true',
        },
      });
    }
  };

  const init = async () => {
    const appConfig = await import(
      `../../config/apps/${process.env.REACT_APP_NAME}`
    );
    if (appConfig) {
      let sub = process.env.REACT_APP_CODE;
      let secret = process.env.REACT_APP_API_KEY;

      if (process.env.REACT_APP_MULTI_BORNES === 'true') {
        const url = document.location.href;
        const regex = /bornes-([^-]+)-([^.]+)\.aquao\.fr/;
        const match = url.match(regex);

        if (match) {
          const currentConfig = appConfig.apiKeys(`${match[1]}-${match[2]}`);
          sub = currentConfig.sub;
          secret = currentConfig.secret;
        }
      }

      const { config }: { config: CoreConfig } = appConfig;
      const data = {
        sub,
        iss: process.env.REACT_APP_ISSUER,
        iat: Math.round(new Date().getTime() / 1000),
        exp: Math.round((new Date().getTime() + 15 * 60 * 1000) / 1000),
      };
      const token = sign(data, CryptoJS.enc.Base64.parse(secret));
      setConfig(prev => ({ ...prev, config }));
      initAuth(token);
    }
  };

  const initAuth = debounce(async (token: string) => {
    await logas(token);
    const result = await whoami();
    if (!result.data?.etablissement) return;
    const {
      data: { element },
    } = await getWebStore(result.data.etablissement.oid);
    setConfig({
      ...config,
      design: {
        colorPrimary: element.bornesData?.themeCouleurPrimaire || '148 183 225',
        colorSecondary:
          element.bornesData?.themeCouleurSecondaire || '55 65 81',
        logo:
          getWebStoreMediaImageUrl(element.medias?.themeImageLogo) ||
          'https://aquao-demo.aquao.fr/static/media/logo-app-header-icon.44957e3d.png',
        savedscreen:
          getWebStoreMediaImageUrl(element.medias?.themeImageEcranVeille) || '',
        homeHeader:
          getWebStoreMediaImageUrl(element.medias?.themeImageHome) || '',
        horsService:
          getWebStoreMediaImageUrl(element.medias?.themeImageHorsService) || '',
        iconRfid:
          getWebStoreMediaImageUrl(element.medias?.themeIconRfid) || undefined,
        iconNoRfid:
          getWebStoreMediaImageUrl(element.medias?.themeIconNoRfid) ||
          undefined,
      },
      params: {
        loggedOnly: element.bornesData?.parametreLoggedOnly === 'true',
        categoryRechargeTemps: +(
          element.bornesData?.parametreCategoryRechargeTemps || '-1'
        ),
        categoryRechargeSeance: +(
          element.bornesData?.parametreCategoryRechargeSeance || '-1'
        ),
        selectionAbonne: element.bornesData?.selectionAbonne
          ? JSON.parse(element.bornesData.selectionAbonne)
          : [],
        selectionClientPassage: element.bornesData?.selectionClientPassage
          ? JSON.parse(element.bornesData.selectionClientPassage)
          : [],
        horsService: element.bornesData?.parametreHorsService === 'true',
        contentCategoryAbonne: element.bornesData?.categorieAbonne || '',
        contentCategoryClientPassage:
          element.bornesData?.categorieNonAbonne || '',
      },
    });
    setCurrentEts(result.data.etablissement);
  }, 500);

  const values: ConfigContextProps = useMemo(
    () => ({
      config,
      etablissement: currentEts,
      setConfig,
      init,
      checkHS,
      whoami,
    }),
    [config, currentEts],
  );

  if (loading) return <Loader />;

  return (
    <ConfigContext.Provider value={values}>
      <style>{`:root { --color-primary: ${config?.design?.colorPrimary};
       --color-secondary: ${config?.design?.colorSecondary}; --font-regular: normal 400 16px/3 Roboto; --font-special: normal 700 16px/3 Roboto; --font-light: normal 300 16px/3 Roboto;}`}</style>
      {children}
    </ConfigContext.Provider>
  );
};
