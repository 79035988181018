import React from 'react';
import { RouteObject } from 'react-router-dom';

export const checkoutRoutes: Array<RouteObject & RouterRoute> = [
  {
    path: 'payment',
    Component: React.lazy(() => import('./views/Payment')),
    index: true,
    layout: 'common',
  },
  {
    path: 'invoice',
    Component: React.lazy(() => import('./views/Invoice')),
    layout: 'common',
  },
  {
    path: 'complete',
    Component: React.lazy(() => import('./views/Complete')),
    layout: 'common',
  },
];
