import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { HeaderCommonLayout, FooterCommonLayout } from 'components/common/sub';
import { Loader } from 'components/loader/loader';

export const CommonLayout = () => {
  const location = useLocation();

  return (
    <>
      <HeaderCommonLayout />
      <div className="overflow-scroll max-h-[calc(100vh-14rem)]">
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </div>
      {!location.pathname.includes('/checkout') ? <FooterCommonLayout /> : null}
    </>
  );
};
