import { XMarkIcon } from '@heroicons/react/24/solid';

export const Modal = ({
  children,
  open,
  onClose,
  title,
  className = '',
  height = 'max-h-[50rem]',
  padding = 'py-32 px-20',
  footer = false,
  contentFooter = undefined,
}: {
  children: React.ReactNode;
  title?: string;
  open: boolean;
  className?: string;
  height?: string;
  padding?: string;
  footer?: boolean;
  onClose?: () => void;
  contentFooter?: React.ReactElement;
}) => {
  if (open)
    return (
      <div className="fixed flex justify-center items-center overflow-x-hidden overflow-y-auto inset-0 outline-none focus:outline-none backdrop-brightness-50 backdrop-blur-sm z-[99]">
        <div className="w-5/6 my-6 mx-auto max-w-6xl">
          <div
            className={`rounded-3xl shadow-2xl flex flex-col align-items justify-center w-full ${height} bg-white outline-none focus:outline-none ${padding} overflow-hidden relative`}
          >
            {!!onClose && (
              <button
                type="button"
                className="absolute top-4 right-4 rounded-full w-12 h-12 bg-white flex justify-center items-center border-4 border-primary"
                onClick={() => onClose()}
              >
                <XMarkIcon className="h-8 w-8 text-primary" />
              </button>
            )}
            {title && (
              <h3 className="text-4xl p-0 rounded-t text-center uppercase text-special text-primary">
                {title}
              </h3>
            )}
            <div className={`text-2xl text-center font-bold ${className}`}>
              {children}
            </div>
            {footer && contentFooter}
          </div>
        </div>
      </div>
    );

  return null;
};

const Footer = ({
  children,
  className = 'flex items-center justify-center mt-10',
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={className}>{children}</div>;
};

Modal.Footer = Footer;
