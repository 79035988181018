import { Invoice, InvoiceLine, CGV, InfosFromFacture } from 'types/checkout';
import { FilterExploitation, ModePaiements } from 'types/exploitation';
import { axios, axiosFile } from 'utils/axios';
import { transformFilters } from 'utils/exploitation';

export const getModePaiements = (
  filter: FilterExploitation,
): Promise<ModePaiements> => {
  return axios
    .get(
      `/modules/codifications-financieres/modes-paiement?${transformFilters(
        filter,
      )}`,
    )
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const getFacture = (oid: number): Promise<Invoice> => {
  return axios
    .get(`/modules/facturation/facture/${oid}`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const getInfosFromFacture = (
  oid: number,
): Promise<InfosFromFacture[]> => {
  return axios
    .get(`/activite/balneo/borne/infos-credits-from-facture/${oid}`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const updateLigneFacture = (data: InvoiceLine): Promise<InvoiceLine> => {
  return axios
    .put(`/modules/facturation/facture/ligne/${data.oid}`, data)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const deleteLignesFacture = (oids: number[]): Promise<number[]> => {
  return axios
    .delete('/modules/facturation/facture/lignes', {
      data: oids,
    })
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const getEncoursByClient = (clientOid: number): Promise<Invoice[]> => {
  return axios
    .get(`/modules/facturation/client/${clientOid}/encours`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const getWebstoreContent = (
  type: string,
  filter?: string,
): Promise<CGV> => {
  return axios
    .get(`/modules/webstore/contents/${type}?${filter}`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const printFacture = (oid: number, output = 'pdf'): Promise<any> => {
  return axiosFile
    .get(`../report/ticketCaisse/${oid}/${output}`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export function notifyFacture(oid: number, code: string): Promise<null> {
  return axios
    .get(`/modules/facturation/facture/notify/${oid}/${code}`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
}
