import { User } from 'types/auth';
import { CartJsonData } from 'types/checkout';
import { Product } from 'types/products';

export const formatJsonData = (
  item: Product,
  qty: number,
  client: User,
  // eslint-disable-next-line default-param-last
  prev = '',
  badge?: string,
): string => {
  if (
    item.clientAuthentifie ||
    (item.type === 'prestation' && item.carteCreation)
  ) {
    const _old = prev?.length ? JSON.parse(prev) : undefined;
    let _qty = qty;
    let _oldJsonData = [];

    if (qty < 1) return '';

    if (_old) {
      const _oldTotal =
        (_old.listClientOid?.length || 0) +
        (_old.listBadges?.length || 0) +
        (_old.listClientOidBadge?.length || 0);

      _oldJsonData = [
        ...(_old.listClientOid || []),
        ...(_old.listBadges || []),
        ...(_old.listClientOidBadge || []),
      ];

      if (_oldTotal > qty) {
        _qty = 0;
        _oldJsonData = _oldJsonData.slice(0, qty - _oldTotal);
      }

      if (_oldTotal < qty) {
        _qty = qty - _oldTotal;
      }
    }

    _qty = _qty < 0 ? 0 : _qty;

    let jsonData: CartJsonData = {
      __type: 'LigneFactureDataClient',
      listClientOid: [..._oldJsonData, ...Array(_qty)].map(() => client.oid),
    };

    let carteCreation = false;
    const { type, clientAuthentifie } = item;

    if (type === 'prestation' || type === 'abonnement') {
      carteCreation = item.carteCreation;
    }

    if (
      (type === 'prestation' && carteCreation && clientAuthentifie) ||
      (type === 'abonnement' && carteCreation && clientAuthentifie)
    ) {
      jsonData = {
        __type: 'LigneFactureDataClientBadge',
        listClientOidBadge: [..._oldJsonData, ...Array(_qty)].map(() => ({
          clientOid: client.oid,
          badge,
        })),
      };
    } else if (type === 'prestation' && carteCreation && !clientAuthentifie) {
      jsonData = {
        __type: 'LigneFactureDataBadge',
        listBadges: [..._oldJsonData, ...Array(_qty)].map(
          (a, i) => `borne_${Date.now()}_${item.oid}_${i}`,
        ),
      };
    }

    return JSON.stringify(jsonData);
  }
  return prev;
};
