export enum EtatProduitCadeau {
  CONSOMMABLE = 'CONSOMMABLE',
  CONSOMME = 'CONSOMME',
}

export enum TypeProduitCadeau {
  CHEQUE_CADEAU_FIXE = 'CHEQUE_CADEAU_FIXE',
}

export type SearchProduitCadeau = {
  acheteur: string;
  code: string;
  dateConsommation?: string;
  dateEmission: string;
  dateValiditeFin: string;
  destinataire?: string;
  etat: EtatProduitCadeau;
  internet?: boolean;
  libelle: string;
  montant: number;
  oid: number;
  quantiteConsommee: number;
  quantiteRestante: number;
  quantiteTotale: number;
  solde: number;
  type: TypeProduitCadeau;
};

export type CommonProduitCadeau = {
  client: { oid: number };
  code: string;
  dateEmission: string;
  dateValiditeDebut: string;
  dateValiditeFin: string;
  destinataire?: string;
  etat: EtatProduitCadeau;
  libelle: string;
  montant: number;
  montantUtilise: number;
  oid: number;
  quantiteConsommee: number;
  quantiteRestante: number;
  quantiteTotale: number;
  solde: number;
};

export type SearchChequeCadeauInput = {
  code: string;
  orderBy?: string;
  orderByDESC?: boolean;
};

export type SearchChequeCadeau = {
  bonsCadeaux: SearchProduitCadeau[];
  nombre: number;
};
