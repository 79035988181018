import { debounce } from 'lodash';

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number,
) {
  const resolveSet = new Set<(p: any) => void>();
  const rejectSet = new Set<(p: any) => void>();

  const debounced = debounce((args: Parameters<F>) => {
    func(...args)
      .then((...res) => {
        resolveSet.forEach(resolve => resolve(...res));
        resolveSet.clear();
      })
      .catch((...res) => {
        rejectSet.forEach(reject => reject(...res));
        rejectSet.clear();
      });
  }, wait);

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      resolveSet.add(resolve);
      rejectSet.add(reject);
      debounced(args);
    }) as ReturnType<F>;
}

export const htmlDecimalEntitieDecode = (val: string) => {
  const arr = val.match(/&#[0-9]{1,5};/g);
  let result: string = val;

  if (arr != null) {
    for (let x = 0; x < arr.length; x += 1) {
      const m = arr[x];
      const c = parseInt(m.substring(2, m.length - 1));
      if (c >= -32768 && c <= 65535) {
        result = result.replace(m, String.fromCharCode(c));
      } else {
        result = result.replace(m, '');
      }
    }
  }

  if (typeof result === 'string' && result.length > 2) {
    if (result.substring(0, 2) === '__') {
      result = result.slice(2);
    }

    result = result.charAt(0).toUpperCase() + result.slice(1);
  }

  return result;
};

export const nl2br = (str: string) => {
  return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};
