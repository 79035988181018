import React from 'react';
import { IagonaPrintItemInput } from 'types/iagona';

export type IagonaContextProps = {
  startInput: (e?: string) => Promise<string | undefined>;
  stopInput: () => Promise<void>;
  startPayment: (params: { amount: number; ref: string }) => Promise<boolean>;
  stopPayment: (params: { ref: string }) => Promise<boolean>;
  printTicket: (items: IagonaPrintItemInput[]) => Promise<boolean>;
  cancelCalls: () => void;
  maintenanceMode: () => Promise<boolean>;
  reboot: () => Promise<boolean>;
  getStatus: () => Promise<boolean>;
  startTransaction: () => Promise<boolean>;
  stopTransaction: () => Promise<boolean>;
};

const BLANK: IagonaContextProps = {
  startInput: () => Promise.reject(new Error('startInput not defined')),
  stopInput: () => Promise.reject(new Error('stopInput not defined')),
  startPayment: () => Promise.reject(new Error('startPayment not defined')),
  stopPayment: () => Promise.reject(new Error('stopPayment not defined')),
  printTicket: () => Promise.reject(new Error('printTiket not defined')),
  cancelCalls: () => Promise.reject(new Error('cancelCalls not defined')),
  reboot: () => Promise.reject(new Error('reboot not defined')),
  maintenanceMode: () =>
    Promise.reject(new Error('maintenanceMode not defined')),
  getStatus: () => Promise.reject(new Error('getStatus not defined')),
  startTransaction: () =>
    Promise.reject(new Error('startTransaction not defined')),
  stopTransaction: () =>
    Promise.reject(new Error('stopTransaction not defined')),
};

const IagonaContext = React.createContext(BLANK);

const useIagona = () => {
  const context = React.useContext(IagonaContext);
  return context;
};

export { IagonaContext, useIagona };
