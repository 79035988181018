import React from 'react';
import { Category } from 'types/categories';
import { ModePaiement } from 'types/exploitation';
import { Product } from 'types/products';

export type FetchProductsParams = {
  category?: number;
  creditTemps?: boolean;
};

export type DataContextProps = {
  categories: Category[];
  products: Product[];
  modePaiements: ModePaiement[];
  fetchCategory: (e: number) => Category | undefined;
  fetchProducts: (e: FetchProductsParams) => Product[];
  refetch: () => Promise<void>;
};

const BLANK: DataContextProps = {
  categories: [],
  products: [],
  modePaiements: [],
  fetchCategory: () => undefined,
  fetchProducts: () => [],
  refetch: () => Promise.resolve(),
};

const DataContext = React.createContext(BLANK);

const useData = () => React.useContext(DataContext);

export { DataContext, useData };
