import { authRoutes } from 'features/authentication/config';
import { homeRoutes } from 'features/home/config';
import { categoriesRoutes } from 'features/category/config';
import { planningRoutes } from 'features/planning/config';
import { checkoutRoutes } from 'features/checkout/config';
import { screensaverRoutes } from 'features/screensaver/config';

import { RouteObject } from 'react-router-dom';

export const routes: Array<RouteObject & RouterRoute> = [
  ...authRoutes,
  ...homeRoutes,
  ...categoriesRoutes,
  ...planningRoutes,
  ...checkoutRoutes,
  ...screensaverRoutes,
];
