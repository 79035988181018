import { Button, Modal } from 'components/ui';
import { useConfig, useData, useIagona } from 'context';
import { toggleWebStoreHS } from 'features/core/stub/token';
import Keyboard from 'react-simple-keyboard';
import { useRef, useState } from 'react';

export const SecretModal = () => {
  const [optionShow, setOptionShow] = useState<boolean>(false);
  const [clickCount, setClickCount] = useState<number>(0);
  const [lastClick, setLastClick] = useState<number>(0);
  const [password, setPassword] = useState<string>('');
  const { maintenanceMode, cancelCalls, reboot } = useIagona();
  const { etablissement, config, checkHS } = useConfig();
  const { refetch } = useData();
  const keyboardRef = useRef<any>(null);

  const handleClick = () => {
    const now = new Date().getTime();
    if (now - lastClick < 1000) {
      setClickCount(clickCount + 1);
    } else {
      setClickCount(1);
    }
    setLastClick(now);
  };

  const toggleHS = async () => {
    if (!etablissement?.oid) return;
    if (config?.params?.horsService) {
      await toggleWebStoreHS(etablissement?.oid, false);
    } else {
      await toggleWebStoreHS(etablissement?.oid, true);
    }
    checkHS();
  };

  const handleRefresh = () => {
    refetch();
  };

  const onValidPassword = async (val: string) => {
    if (val.length > 4) {
      keyboardRef.current.setInput(val.slice(0, 4));
      return;
    }
    setPassword(val);
    if (val.length < 4) return;
    // eslint-disable-next-line no-promise-executor-return
    await new Promise(resolve => setTimeout(resolve, 800));
    keyboardRef.current.clearInput();
    setPassword('');
    if (val === '1412') {
      setClickCount(0);
      setOptionShow(true);
    }
  };

  return (
    <>
      <Modal open={clickCount >= 3} onClose={() => setClickCount(0)}>
        <div className="flex justify-center items-center h-32 w-1/2 m-auto relative">
          <button
            type="button"
            className="absolute top-8 left-full ml-10 text-red-600 p-2 text-5xl"
            onClick={() => {
              const keyboard = keyboardRef.current;
              if (!keyboard) return;
              const current = keyboard.getInput();
              if (current.length > 0) {
                keyboard.setInput(current.slice(0, -1));
                setPassword(current.slice(0, -1));
              }
            }}
          >
            ⌫
          </button>
          <div className="gap w-full grid grid-cols-4">
            {Array.from({ length: 4 }).map((_, i) => (
              <div
                className="col-span-1 flex justify-center item-center"
                key={i}
              >
                <span
                  className={`h-10 w-10 rounded-full ${
                    password?.length > i ? 'bg-gray-800' : 'bg-slate-200'
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
        <Keyboard
          onChange={val => onValidPassword(val)}
          keyboardRef={r => (keyboardRef.current = r)}
          layout={{
            default: ['1 2 3', '4 5 6', '7 8 9', '0'],
          }}
          buttonTheme={[
            { class: '!h-24', buttons: '{bksp} 1 2 3 4 5 6 7 8 9 0' },
          ]}
        />
      </Modal>
      <Modal open={optionShow} onClose={() => setOptionShow(false)}>
        <div className="gap-4 grid grid-cols-1">
          <Button onClick={handleRefresh} className="w-full">
            Recharger les vendables
          </Button>
          <Button
            onClick={() => {
              cancelCalls();
              document.location.href = `/?reload=${Date.now()}`;
            }}
            className="w-full"
          >
            Recharger la page
          </Button>
          <Button
            onClick={() => {
              maintenanceMode();
            }}
            className="w-full"
          >
            Mode maintenance
          </Button>
          <Button
            onClick={() => {
              reboot();
            }}
            className="w-full"
          >
            Redémarrer la borne
          </Button>
          <Button
            onClick={() => {
              toggleHS();
            }}
            className="w-full"
          >
            {config?.params?.horsService ? 'Désactiver' : 'Activer'} le mode
            hors service
          </Button>
          <Button onClick={() => setOptionShow(false)} className="w-full">
            Fermer
          </Button>
        </div>
      </Modal>
      <div
        className="fixed top-0 left-0 h-10 w-10 z-[99999]"
        onClick={() => handleClick()}
      />
    </>
  );
};
