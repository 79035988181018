export const RemainingNotification = ({
  remaining,
  open,
}: {
  remaining: number;
  open: boolean;
}) => {
  if (open)
    return (
      <div className="fixed flex justify-center items-center overflow-x-hidden overflow-y-auto inset-0 outline-none focus:outline-none backdrop-brightness-50 backdrop-blur-sm z-[99]">
        <div className="w-5/6 my-6 mx-auto max-w-6xl">
          <div className="rounded-3xl shadow-2xl flex flex-col align-items justify-center w-full p-32 bg-white outline-none focus:outline-none overflow-hidden">
            <h3 className="text-3xl p-0 rounded-t text-center uppercase text-special text-primary">
              Vous allez être déconnecté(e) dans {Math.round(remaining)}{' '}
              secondes
            </h3>
            <div className="text-2xl text-center font-bold">
              <div className="flex items-center">
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="mt-12 text-lg text-gray-500">
                    Pour conserver votre cession active cliquer sur
                  </p>
                  <button
                    type="button"
                    className="bg-primary text-white mt-6 py-2 px-8 rounded-full"
                  >
                    Continuer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return null;
};
