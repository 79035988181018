import {
  CartArticle,
  VenteAbonnementInput,
  VenteArticleInput,
  VentePrestationInput,
  CartAbonnement,
  CartPrestation,
  PaiementInput,
  Paiement,
  Invoice,
  CreditTemps,
} from 'types/checkout';
import { axios, axiosFile } from 'utils/axios';

export const getBalneoClientEncours = (oid: number): Promise<Invoice> => {
  return axios
    .get(`/activite-balneo/client/${oid}/encours`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const addBalneoArticle = (
  oidEncours: number,
  data: VenteArticleInput,
): Promise<CartArticle> => {
  return axios
    .post(`/activite-balneo/encours/${oidEncours}/vente/article`, data)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const addBalneoAbonnement = (
  oidEncours: number,
  data: VenteAbonnementInput,
): Promise<CartAbonnement> => {
  return axios
    .post(`/activite-balneo/encours/${oidEncours}/vente/abonnement`, data)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const addBalneoPrestation = (
  oidEncours: number,
  data: VentePrestationInput,
): Promise<CartPrestation> => {
  return axios
    .post(`/activite-balneo/encours/${oidEncours}/vente/prestation`, data)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const createBalneoVente = (data: PaiementInput): Promise<Paiement> => {
  return axios
    .post('/activite-balneo/paiement', data)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const getCreditTempsByBadge = (code: string): Promise<CreditTemps[]> => {
  return axios
    .get(`/activite/balneo/compteCreditTemps/badge/${code}`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const getQrCodeCreditTemps = (oid: number) => {
  return axiosFile
    .get(`/activite/balneo/compteCreditTemps/${oid}/qrcode`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};
