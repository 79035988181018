import { ElementsResponse } from 'types/products';

export const formatProducts = (data: ElementsResponse) => {
  return [
    ...data.abonnements.map(item => ({ ...item, type: 'abonnement' })),
    /* ...data.forfaits.map(item => ({ ...item, type: 'forfait' })), */
    ...data.articles.map(item => ({ ...item, type: 'article' })),
    ...data.prestations.map(item => ({ ...item, type: 'prestation' })),
    ...data.produitsCadeaux.map(item => ({
      ...item,
      type: 'produitCadeau',
    })),
  ]
    .map(item => ({
      ...item,
      prix:
        (item.tarifs || []).find(
          tarif => tarif.code === item.webElements.codeTarif,
        )?.prix || 0,
    }))
    .sort((a, b) => ((a.poids || 0) > (b.poids || 0) ? 1 : -1));
};

export const formatCategories = (data: ElementsResponse) => {
  return data.categories.sort((a, b) =>
    (a.poids || 0) > (b.poids || 0) ? 1 : -1,
  );
};
