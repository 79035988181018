import { FilterExploitation } from 'types/exploitation';

export const transformFilters = (filters: FilterExploitation): string => {
  let transformedFilters: string[] = [];

  Object.keys(filters).forEach(key => {
    transformedFilters = [...transformedFilters, `${[key]}=${filters[key]}`];
  });

  return encodeURI(transformedFilters.join('&'));
};
