import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from 'config/routes';
import { AuthLayout, CommonLayout, Layout, Transaction } from 'components';
import { useEffect, lazy } from 'react';
import {
  AuthProvider,
  CoreProvider,
  DataProvider,
  ConfigProvider,
  IagonaProvider,
} from './context';

export const App = () => {
  useEffect(() => {
    window.addEventListener('error', event => {
      const isChunkLoadFailed =
        event.message &&
        (event.message.includes("Uncaught SyntaxError: Unexpected token '<'") ||
          event.message.includes(
            'The connection has not been established yet',
          ));

      if (isChunkLoadFailed) {
        document.location.href = `${
          document.location.href.split('?')[0]
        }?reload=${new Date().getTime()}`;
      }
    });
  }, []);

  const appRoutes = createBrowserRouter([
    ...routes.filter(({ layout }) => layout === undefined),
    {
      path: '/',
      element: <Transaction />,
      children: [
        {
          path: '/',
          element: <AuthLayout />,
          children: routes.filter(({ layout }) => layout === 'auth'),
        },
        {
          path: '/home',
          element: <CommonLayout />,
          children: routes.filter(({ layout }) => layout === 'common'),
        },
        {
          path: '/category',
          element: <CommonLayout />,
          children: routes.filter(({ layout }) => layout === 'common'),
        },
        {
          path: '/planning',
          element: <CommonLayout />,
          children: routes.filter(({ layout }) => layout === 'common'),
        },
        {
          path: '/checkout',
          element: <CommonLayout />,
          children: routes.filter(({ layout }) => layout === 'common'),
        },
        {
          path: '/app',
          element: <Layout />,
          children: routes.filter(({ layout }) => layout === 'app'),
        },
      ],
    },
    {
      path: '*',
      Component: lazy(() => import('./features/screensaver/views/Screen')),
    },
  ]);

  return (
    <ConfigProvider>
      <AuthProvider>
        <IagonaProvider>
          <DataProvider>
            <CoreProvider>
              <RouterProvider router={appRoutes} />
            </CoreProvider>
          </DataProvider>
        </IagonaProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};
