import { Loader } from 'components';
import { useAuth, useCore, useIagona } from 'context';
import { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { RemainingNotification } from 'components/common/sub';

export const Transaction = () => {
  const [remaining, setRemaining] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);
  const { getHardwareStatus, basket, reset } = useCore();
  const { client, setClient } = useAuth();
  const { startTransaction, stopTransaction, cancelCalls, stopInput } =
    useIagona();
  const location = useLocation();
  const navigate = useNavigate();

  const getTimeout = (pathname: string) => {
    if (['/checkout/payment', '/scan'].includes(pathname)) return 300_000;
    return 60_000;
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: getTimeout(location.pathname),
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(getRemainingTime() / 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [getRemainingTime]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') onIdle();
  }, [remaining]);

  const onIdle = async () => {
    if (!['/', '/screensaver'].includes(location.pathname) && remaining <= 1) {
      await stopInput();
      await reset();
      navigate('/');
    }
  };

  const showModal = () => {
    return (
      !['/', '/screensaver'].includes(location.pathname) && remaining <= 20
    );
  };

  useEffect(() => {
    if (!client && !['/', '/auth'].includes(location.pathname)) {
      reset();
      navigate('/');
    }
  }, [client, basket]);

  useEffect(() => {
    if (!['/', '/screensaver'].includes(location.pathname)) {
      handleInit();
    }
    return () => {
      cancelCalls();
      handleUnmount();
    };
  }, []);

  const handleInit = async () => {
    setLoading(true);
    const result = await startTransaction();
    if (!result) {
      setLoading(false);
      navigate('/');
    }
    setLoading(false);
    getHardwareStatus();
  };

  const handleUnmount = async () => {
    setLoading(true);
    await getHardwareStatus();
    await stopTransaction();
    setClient(undefined);
  };

  if (loading) return <Loader />;

  return (
    <>
      <RemainingNotification remaining={remaining} open={showModal()} />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </>
  );
};
