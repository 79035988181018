import React from 'react';
import { RouteObject } from 'react-router-dom';

export const planningRoutes: Array<RouteObject & RouterRoute> = [
  {
    path: '',
    Component: React.lazy(() => import('../planning/Planning')),
    index: true,
    layout: 'common',
  },
];
