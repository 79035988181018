import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';

// import { HeaderAuthLayout } from './sub';
import bgAuthLayout from 'assets/images/bg-auth-layout.jpg';
import { Loader } from 'components/loader/loader';

export const AuthLayout = () => {
  return (
    <>
      <img
        src={bgAuthLayout}
        alt="bg-auth-layout"
        className="w-full h-full top-0 left-0 object-cover z-0"
      />
      {/* <HeaderAuthLayout /> */}
      <div className="absolute top-0 left-0 w-full h-full flex items-start justify-center pt-[680px]">
        <div className="w-11/12 mt-10 z-10">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  );
};
