import { axios } from 'utils/axios';

export function getClientPassage() {
  return axios
    .get('/modules/client-passage')
    .then(res => Promise.resolve(res))
    .catch(res => Promise.reject(res));
}

export function getClientByBadge(uid: string, filter = '') {
  return axios
    .get(`/activite/balneo/badge/resume/uid/${uid}?${filter}`)
    .then(res => Promise.resolve(res))
    .catch(res => Promise.reject(res));
}
