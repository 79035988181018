import React from 'react';
import { AuthConfig } from 'types/auth';
import { Etablissement } from 'types/core';

export type AuthContextProps = {
  client?: AuthConfig;
  etablissement?: Etablissement;
  setClient: (e?: AuthConfig) => void;
  isAuthenticated: boolean;
  badge?: string;
  loginClient: (e?: string) => Promise<string>;
};

const BLANK: AuthContextProps = {
  client: undefined,
  etablissement: undefined,
  setClient: () => new Error('setClient not defined'),
  isAuthenticated: false,
  badge: undefined,
  loginClient: () => Promise.reject(new Error('loginClient not defined')),
};

const AuthContext = React.createContext(BLANK);

const useAuth = () => {
  const context = React.useContext(AuthContext);
  return context;
};

export { AuthContext, useAuth };
