import { useCore } from 'context';
import {
  ChevronDownIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Button, Modal, DefaultKeyboard } from 'components/ui';
import { useState } from 'react';

export const Basket = ({
  setOpen,
  opened,
}: {
  setOpen: (e?: boolean) => void;
  opened: boolean;
}) => {
  const {
    basket,
    negativeLines,
    removeNegativeLine,
    updateBasketLine,
    deleteBasketLine,
    addCodePromo,
    addChequeCadeau,
  } = useCore();
  const [openModal, setOpenModal] = useState<'codePromo' | 'chequeCadeau'>();
  const [codePromo, setCodePromo] = useState<string>('');
  const [chequeCadeau, setChequeCadeau] = useState<string>('');

  const columns = [
    {
      title: 'Désignation',
      key: 'libelle',
      className: 'col-span-6 text-start ml-4',
    },
    {
      title: 'Prix',
      key: 'price',
      className: 'col-span-1 text-right',
    },
    {
      title: 'Qté',
      key: 'quantity',
      className: 'col-span-2 text-center',
    },
    {
      title: 'Total',
      key: 'solde',
      className: 'col-span-2 text-right',
    },
    {
      title: '',
      key: 'action',
      className: 'col-span-1 text-center',
    },
  ];

  const handleChangeQuatity = (type: string, lineOid: number) => {
    const _line = (basket?.lignes || []).find(({ oid }) => oid === lineOid);
    if (!_line) return;
    updateBasketLine({
      ..._line,
      quantite: type === 'more' ? _line.quantite + 1 : _line.quantite - 1,
    });
  };

  const handleRemoveLine = (lineOid: number) => {
    deleteBasketLine(lineOid);
  };

  const closeModal = () => {
    setOpenModal(undefined);
    setCodePromo('');
    setChequeCadeau('');
  };

  const applyCodePromo = () => {
    if (!codePromo) return;
    addCodePromo(codePromo);
    closeModal();
  };

  const applyChequeCadeau = async () => {
    if (!chequeCadeau) return;
    await addChequeCadeau(chequeCadeau);
    closeModal();
  };

  return (
    <>
      <div
        className={`fixed transition-all duration-300 ease-linear bottom-0 left-0 h-[calc(100vh-27rem)] w-full bg-white flex justify-center p-12 z-40 ${
          opened ? 'top-[14rem]' : 'top-full'
        } mb-52`}
      >
        <div className="absolute bottom-full h-12 bg-gradient-to-t from-black opacity-20 inset-x-0 z-20" />
        <div className="flex flex-col justify-center text-center w-full items-center">
          <div className="uppercase text-special text-4xl text-primary">
            Mon Panier
          </div>
          <table className="text-xl w-full mt-36 mb-16">
            <thead>
              <tr className="grid grid-cols-12 gap-2 text-gray-400 mb-7 uppercase text-lg">
                {columns.map(c => (
                  <th className={c.className} key={c.key}>
                    {c.title}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {(basket?.lignes || []).map((item, index) => (
                <tr
                  className={`grid grid-cols-12 gap-4 ${
                    index % 2 === 0 ? 'bg-gray-100' : ''
                  } py-4 px-3 rounded-md font-bold items-center`}
                  key={index}
                >
                  <td className="col-span-6 text-start truncate">
                    {item.libelle}
                  </td>
                  <td className="col-span-1 text-right">
                    {Intl.NumberFormat('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(item.montantUnitaireTTC)}
                  </td>
                  <td className="col-span-2 flex justify-center items-center">
                    {item.quantite > 0 && (
                      <>
                        <button
                          className="inline-block align-middle"
                          type="button"
                          onClick={() => handleChangeQuatity('less', item.oid)}
                        >
                          <MinusCircleIcon className="h-9 w-9" />
                        </button>{' '}
                        <p className="mx-3">{item.quantite}</p>{' '}
                        <button
                          className="inline-block align-middle"
                          type="button"
                          onClick={() => handleChangeQuatity('more', item.oid)}
                        >
                          <PlusCircleIcon className="h-9 w-9" />
                        </button>
                      </>
                    )}
                  </td>
                  <td className="col-span-2 text-right">
                    {Intl.NumberFormat('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(item.montantNetTTC)}
                  </td>
                  <td
                    className="col-span-1 flex justify-end"
                    onClick={() => handleRemoveLine(item.oid)}
                  >
                    <TrashIcon className="h-6 w-6" />
                  </td>
                </tr>
              ))}
              {(negativeLines || []).map((item, index) => (
                <tr
                  className={`grid grid-cols-12 gap-4 ${
                    index % 2 === 0 ? 'bg-gray-100' : ''
                  } py-4 px-3 rounded-md font-bold items-center`}
                  key={item.key}
                >
                  <td className="col-span-6 text-start truncate">
                    {item.libelle}
                  </td>
                  <td className="col-span-1 text-right" />
                  <td className="col-span-2 flex justify-center items-center" />
                  <td className="col-span-2 text-right">
                    {Intl.NumberFormat('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(item.amount * -1)}
                  </td>
                  <td
                    className="col-span-1 flex justify-end"
                    onClick={() => removeNegativeLine(item.key)}
                  >
                    <TrashIcon className="h-6 w-6" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="grid grid-cols-2 w-full gap-12 mb-16">
            <div className="span-col-1">
              <Button
                color="white"
                className="uppercase w-full"
                onClick={() => setOpenModal('codePromo')}
              >
                J'ai un code promo
              </Button>
            </div>
            <div className="span-col-1">
              <Button
                color="white"
                className="uppercase w-full"
                onClick={() => setOpenModal('chequeCadeau')}
              >
                J'ai un chèque cadeau
              </Button>
            </div>
          </div>
          <div
            className="uppercase text-2xl bottom-0 text-light flex items-center"
            onClick={() => setOpen(false)}
          >
            <ChevronDownIcon className="h-6 w-6 mr-2" /> Masquer
          </div>
        </div>
      </div>
      <Modal
        title="Saisissez votre code promo"
        open={openModal === 'codePromo'}
        onClose={() => closeModal()}
      >
        <input
          className="w-full bg-slate-200 text-4xl p-6 text-center rounded-2xl my-12"
          onChange={e => setCodePromo(e.target.value)}
          value={codePromo}
        />
        <DefaultKeyboard onChange={val => setCodePromo(val)} />
        <Button className="uppercase w-full p-6 mt-10" onClick={applyCodePromo}>
          Ajouter
        </Button>
        <Button
          onClick={closeModal}
          color="none"
          className="text-2xl py-8 px-6 w-44 border-0"
        >
          Fermer
        </Button>
      </Modal>
      <Modal
        title="Saisissez votre chèque cadeau"
        open={openModal === 'chequeCadeau'}
        onClose={() => closeModal()}
      >
        <input
          className="w-full bg-slate-200 text-4xl p-6 text-center rounded-2xl my-12"
          onChange={e => setChequeCadeau(e.target.value)}
          value={chequeCadeau}
        />
        <DefaultKeyboard onChange={val => setChequeCadeau(val)} />
        <Button
          className="uppercase w-full p-6 mt-10"
          onClick={applyChequeCadeau}
        >
          Ajouter
        </Button>
        <Button
          onClick={closeModal}
          color="none"
          className="text-2xl py-8 px-6 w-44 border-0"
        >
          Fermer
        </Button>
      </Modal>
    </>
  );
};
