import { useEffect, useState } from 'react';

import { AuthConfig } from 'types/auth';
import {
  getClientByBadge,
  getClientPassage,
} from 'features/authentication/stub';
import * as Sentry from '@sentry/react';
import { AuthContext } from './auth.context';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [client, setClient] = useState<AuthConfig>();
  const [badge, setBadge] = useState<string>();

  useEffect(() => {
    if (
      !client &&
      !['/', '/screensaver', '/auth'].includes(document.location.pathname)
    ) {
      document.location.href = `/?reload=${Date.now()}`;
    }
  }, [client]);

  const loginClientByBadge = async (badge: string): Promise<string> =>
    new Promise((resolve, reject) => {
      Sentry.captureMessage(`StartLoginByBadge ${new Date().getTime()}`, {
        level: 'info',
        extra: {
          badge,
        },
      });

      getClientByBadge(badge)
        .then(({ data }) => {
          Sentry.captureMessage(
            `StartLoginByBadge Result 200 ${new Date().getTime()}`,
            {
              level: 'info',
              extra: {
                client: data?.badge?.element?.client?.oid,
              },
            },
          );
          if (data?.badge?.element?.client?.oid) {
            setClient({
              client: {
                ...data?.badge?.element?.client,
                code: data?.badge?.element?.code,
              },
              comptesCreditTemps: data?.resumeComptesCreditTemps,
              comptesSeance: data?.resumeComptesSeance,
            });
            setBadge(data.badge.element.code);
            resolve('/home/auth');
          } else {
            Sentry.captureMessage(
              `StartLoginByBadge Error no client Oid ${new Date().getTime()}`,
              {
                level: 'info',
                extra: {
                  badge,
                },
              },
            );
            reject();
          }
        })
        .catch(err => {
          Sentry.captureMessage(
            `StartLoginByBadge Error ${new Date().getTime()}`,
            {
              level: 'info',
              extra: {
                message: err.message,
              },
            },
          );
          reject();
        });
    });

  const loginClientPassage = async (): Promise<string> =>
    new Promise(resolve => {
      getClientPassage().then(({ data }) => {
        setClient({
          client: data,
          comptesCreditTemps: {},
          comptesSeance: {},
        });
        setBadge(`borne_${data.oid}_${Date.now()}`);
        resolve('/home/not-auth');
      });
    });

  const handleLogin = async (badge?: string): Promise<string> => {
    if (badge) {
      return loginClientByBadge(badge);
    }
    return loginClientPassage();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!client,
        client,
        setClient,
        badge,
        loginClient: handleLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
