import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MinusCircleIcon,
  PlusCircleIcon,
  ExclamationCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { getWebstoreContent } from 'features/checkout/stub/facturation';

import { Modal, Button } from 'components/ui';
import { useConfig, useCore } from 'context';

type Contents = {
  cgv: string;
  rgpd: string;
  cgu: string;
};

type ValidContent = {
  cgv?: boolean;
  rgpd?: boolean;
  cgu?: boolean;
};

export const ModalCGV = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (e?: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { etablissement } = useConfig();
  const { basket, updateBasketLine, deleteBasketLine } = useCore();

  const [content, setContent] = useState<Contents>();
  const [isAccept, setIsAccept] = useState<ValidContent>();
  const [opened, setOpened] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchContents();
    return () => {
      setIsAccept(undefined);
    };
  }, []);

  async function fetchContents() {
    const _CGV = await getWebstoreContent(
      'cgv',
      `_for.etablissement=${etablissement?.oid}`,
    );
    const _RGPD = await getWebstoreContent(
      'rgpd',
      `_for.etablissement=${etablissement?.oid}`,
    );
    const _CGU = await getWebstoreContent(
      'cgu',
      `_for.etablissement=${etablissement?.oid}`,
    );

    setContent({
      cgv: _CGV.content,
      rgpd: _RGPD.content,
      cgu: _CGU.content,
    });
  }

  const columns = [
    {
      title: 'Désignation',
      key: 'libelle',
      className: 'col-span-5 text-start',
    },
    {
      title: 'Prix',
      key: 'price',
      className: 'col-span-2 text-right',
    },
    {
      title: 'Qté',
      key: 'quantity',
      className: 'col-span-2 text-center',
    },
    {
      title: 'Total',
      key: 'solde',
      className: 'col-span-2 text-right',
    },
    {
      title: '',
      key: 'action',
      className: 'col-span-1 text-center',
    },
  ];

  const handleChangeQuatity = (type: string, lineOid: number) => {
    const _line = (basket?.lignes || []).find(({ oid }) => oid === lineOid);
    if (!_line) return;
    updateBasketLine({
      ..._line,
      quantite: type === 'more' ? _line.quantite + 1 : _line.quantite - 1,
    });
  };

  const handleRemoveLine = (lineOid: number) => {
    deleteBasketLine(lineOid);
  };

  function handleClose() {
    setOpen(false);
    if (basket?.total === 0) return;
    navigate('/checkout/payment');
  }

  const getContent = () => {
    switch (opened) {
      case 'cgv':
        return content?.cgv;
      case 'rgpd':
        return content?.rgpd;
      case 'cgu':
        return content?.cgu;
      default:
        return '';
    }
  };

  const contentFooter = (
    <Modal.Footer>
      <Button
        className="ml-5 text-2xl py-2 px-6 w-44"
        onClick={() => handleClose()}
        disabled={!(isAccept?.cgv && isAccept?.rgpd)}
        color={isAccept?.cgv && isAccept?.rgpd ? 'secondary' : 'disabled'}
      >
        Continuer
      </Button>
    </Modal.Footer>
  );

  return (
    <>
      <Modal
        open={open}
        title="Mon Panier"
        height="max-h-[70rem]"
        className="overflow-scroll"
        footer
        contentFooter={contentFooter}
        onClose={() => setOpen(false)}
      >
        <table className="text-xl w-full my-12">
          <thead>
            <tr className="grid grid-cols-12 gap-2 text-gray-400 mb-2 uppercase text-lg">
              {columns.map(c => (
                <th className={c.className} key={c.key}>
                  {c.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {(basket?.lignes || []).map((item, index) => (
              <tr
                className={`grid grid-cols-12 gap-4 ${
                  index % 2 === 0 ? 'bg-gray-100' : ''
                } py-2 px-2 rounded-md font-bold items-center`}
                key={index}
              >
                <td className="col-span-5 text-start truncate">
                  {item.libelle}
                </td>
                <td className="col-span-2 text-right">
                  {Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(item.montantUnitaireTTC)}
                </td>
                <td className="col-span-2 flex justify-center items-center">
                  <button
                    className="inline-block align-middle"
                    type="button"
                    onClick={() => handleChangeQuatity('less', item.oid)}
                  >
                    <MinusCircleIcon className="h-9 w-9" />
                  </button>{' '}
                  <p className="mx-2">{item.quantite}</p>{' '}
                  <button
                    className="inline-block align-middle"
                    type="button"
                    onClick={() => handleChangeQuatity('more', item.oid)}
                  >
                    <PlusCircleIcon className="h-9 w-9" />
                  </button>
                </td>
                <td className="col-span-2 text-right">
                  {Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(item.montantNetTTC)}
                </td>
                <td
                  className="col-span-1 flex justify-end"
                  onClick={() => handleRemoveLine(item.oid)}
                >
                  <TrashIcon className="h-6 w-6" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pt-6 pl-4 text-special text-2xl text-[gray] flex">
          <input
            type="checkbox"
            checked={isAccept?.cgv}
            className="h-6 w-6 rounded border-[gray] text-[gray] focus:ring-[gray] mt-1"
            onChange={() => setIsAccept({ ...isAccept, cgv: !isAccept?.cgv })}
          />
          <p className="ml-3">
            J'ai lu et j'accepte les{' '}
            <strong
              className="text-primary underline"
              onClick={() => setOpened('cgv')}
            >
              conditions générales de vente
            </strong>
            .
          </p>
        </div>
        <div className="mt-4 pl-4 text-special text-2xl text-[gray] flex">
          <input
            type="checkbox"
            checked={isAccept?.rgpd}
            className="h-6 w-6 rounded border-[gray] text-[gray] focus:ring-[gray] mt-1"
            onChange={() => setIsAccept({ ...isAccept, rgpd: !isAccept?.rgpd })}
          />
          <p className="ml-3">
            J'ai lu et j'accepte la{' '}
            <strong
              className="text-primary underline"
              onClick={() => setOpened('rgpd')}
            >
              politique de confidentialité
            </strong>
            .
          </p>
        </div>
        {!isAccept?.cgv || !isAccept?.rgpd ? (
          <div
            className="flex justify-center items-center mt-8 underline text-md text-primary cursor-pointer"
            onClick={() => setIsAccept({ rgpd: true, cgu: true, cgv: true })}
          >
            Tout accepter
          </div>
        ) : null}

        <div className="flex gap-2 rounded-xl mb-4 mt-12 items-center p-4 bg-orange-100">
          <ExclamationCircleIcon className="h-24 w-24 text-orange-600" />
          <div className="text-md text-orange-600">
            Les recharges ajoutées à votre panier seront créditées
            automatiquement sur le Pass utilisé pour cet achat.
          </div>
        </div>
      </Modal>
      <Modal
        open={!!opened}
        height="max-h-[70rem]"
        className="z-50 overflow-scroll transition-all scrollbar text-md text-gray-700"
        onClose={() => setOpened(undefined)}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: getContent() || '',
          }}
        />
      </Modal>
    </>
  );
};
