import { CommonProduitCadeau, SearchChequeCadeau } from 'types/produitsCadeau';
import { axios } from 'utils/axios';

export const searchCadeaux = (code: string): Promise<SearchChequeCadeau> => {
  return axios
    .post(`/modules/cadeaux/recherche`, {
      code: code.trim(),
      orderBy: 'CODE',
      orderByDESC: false,
      page: 0,
      pageSize: 10,
    })
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const getChequeCadeauByOid = (
  oid: number,
): Promise<CommonProduitCadeau> => {
  return axios
    .get(`/modules/cadeaux/cheque-cadeau/${oid}`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};
