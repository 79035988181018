import { Invoice } from 'types/checkout';
import { ApplyCodePromoInput } from 'types/promo';
import { axios } from 'utils/axios';

export const applyCodePromo = (
  factureOid: number,
  data: ApplyCodePromoInput,
): Promise<Invoice> => {
  return axios
    .post(`/modules/facturation/facture/${factureOid}/codepromo/apply`, data)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};

export const removeCodePromo = (factureOid: number): Promise<Invoice> => {
  return axios
    .get(`/modules/facturation/facture/${factureOid}/codepromo/remove`)
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};
