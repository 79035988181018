import React from 'react';
import { RouteObject } from 'react-router-dom';

export const screensaverRoutes: Array<RouteObject & RouterRoute> = [
  {
    path: '/',
    Component: React.lazy(() => import('./views/Screen')),
    layout: undefined,
  },
  {
    path: '/screensaver',
    Component: React.lazy(() => import('./views/Screen')),
    index: true,
    layout: undefined,
  },
];
