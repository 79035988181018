import { useConfig } from 'context';

export const HeaderCommonLayout = () => {
  const { config } = useConfig();

  return (
    <div className="flex justify-center items-center h-[14rem] bg-primary sticky top-0 z-40">
      <img className="h-32" src={config?.design?.logo} alt="Aquao" />
    </div>
  );
};
