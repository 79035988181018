import React from 'react';
import { RouteObject } from 'react-router-dom';

export const authRoutes: Array<RouteObject & RouterRoute> = [
  {
    path: '/auth',
    Component: React.lazy(() => import('./views/Auth')),
    layout: 'auth',
  },
  {
    path: '/scan',
    Component: React.lazy(() => import('./views/Scan')),
    layout: 'auth',
  },
];
